'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { AmountSavedButton } from '@/app/components/AmountSavedButton';
import { ExclamationTriangleIcon } from '@/app/components/icons/ExclamationTriangleIcon';
import { Loader } from '@/app/components/Loader';
import { SheerCasePreview } from '@/app/components/sheercase/SheerCasePreview';
import { SupportButtonSection } from '@/app/components/SupportButtonSection';
import useEventUpdates from '@/hooks/useEventUpdates';
import useMemberData from '@/hooks/useMemberData';

const getGreeting = () => {
  const hrs = new Date().getHours();
  return hrs < 12
    ? 'Good morning'
    : hrs <= 17
      ? 'Good afternoon'
      : 'Good evening';
};

const HomePage = () => {
  const router = useRouter();
  const { isLoading, memberData, memberConnections, amountSaved } =
    useMemberData();
  const { events: memberEvents } = useEventUpdates();

  const ConnectionTile = () => {
    const validConnections = memberConnections.filter(
      connection =>
        connection.username &&
        connection.payerName &&
        connection.inactive === false,
    );
    const faultedConnections = validConnections.filter(
      connection => connection.faulted,
    );

    if (!validConnections.length) return null;

    if (faultedConnections.length) {
      return (
        <div className='flex items-center justify-center gap-2 bg-[#FDE5EA] text-[#CA244D] text-[16px] p-3 lg:px-5 rounded-[4px] w-full lg:w-fit text-nowrap'>
          {' '}
          <ExclamationTriangleIcon /> Disconnected from{' '}
          {faultedConnections[0].payerName}{' '}
        </div>
      );
    }
    return (
      <p className='bg-[#E7F9F5] text-[#067A6F] text-[16px] p-3 lg:px-5 rounded-[4px] text-center w-full lg:w-fit text-nowrap'>
        ✓{' '}
        {validConnections.length > 1
          ? `${validConnections.length} connections`
          : `Connected to ${validConnections[0]?.payerName}`}
      </p>
    );
  };

  const ConnectionSection = () => (
    <Link
      className='flex flex-col w-full gap-4 pb-2 cursor-pointer lg:flex-row lg:max-w-[528px] overflow-scroll'
      href={'/insurance'}
    >
      <ConnectionTile />
      <p className='bg-[#F5EFFF] text-[#3D155D] text-[16px] p-3 lg:px-5 rounded-[4px] text-center w-full lg:w-fit text-nowrap'>
        {'View connections ->'}
      </p>
    </Link>
  );

  const renderRecentEventList = () => {
    const maxDisplay = 3;
    const truncatedEvents = memberEvents.slice(0, maxDisplay);
    if (!truncatedEvents.length) {
      return (
        <div className='flex flex-col w-full gap-4 p-2 lg:w-full'>
          <p className='text-[18px] text-[#65636D]'>0 open messages</p>
          <SheerCasePreview
            title='View all messages'
            onClick={() => router.push('/messages')}
          />
        </div>
      );
    }
    return (
      <div className='flex flex-col w-full gap-4 p-2 lg:w-full'>
        <p className='text-[18px] text-[#65636D]'>Recent messages</p>
        {truncatedEvents.map(event => (
          <SheerCasePreview
            key={event.id}
            title={event.title}
            status={event.status}
            onClick={() => router.push(`/messages/${event.id}`)}
          />
        ))}
        <SheerCasePreview
          title='View all messages'
          onClick={() => router.push('/messages')}
        />
      </div>
    );
  };

  if (isLoading) return <Loader />;

  return (
    <div className='flex flex-col items-start justify-center w-full gap-6 px-4 py-6 bg-white lg:py-12 lg:flex-row lg:px-36'>
      <div className='flex flex-col w-full gap-4 px-4 lg:max-w-2/3 lg:w-fit'>
        <p className='text-[24px] lg:text-[32px] text-[#211F26] font-arizona-flare'>
          {`${getGreeting()}, ${memberData.firstName}.`}
        </p>
        <ConnectionSection />
        <hr className='max-w-[528px]' />

        <p className='text-[20px] text-[#65636D]'>How can we help you today?</p>
        <SupportButtonSection />
        {!!amountSaved && (
          <>
            <hr className='max-w-[528px] my-3' />
            <AmountSavedButton
              amountSaved={amountSaved}
              memberId={memberData.id}
            />
          </>
        )}
      </div>
      <div className='flex flex-col w-full gap-6 px-4 lg:w-5/12 2xl:w-1/3'>
        {renderRecentEventList()}
      </div>
    </div>
  );
};

export default HomePage;
